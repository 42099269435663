
import moment from 'moment'
import Vue, { PropType } from 'vue'
import api from '../../api/api'

import Breadcrumbs from '../../components/Breadcrumbs.vue'
import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { Connector, Project } from '../../types/main'
import { CONNECTOR_TYPES } from '../../vars/general'
import BaseInputOutlined from '../../components/inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../components/inputs/CustomActivatorSelect.vue'
import _ from 'lodash'
import { numSort, floatNumSort } from '../../utils'

export default Vue.extend({
    name: 'GCB2MarketingReport',
    components: {
        Breadcrumbs,
        SpinnerLoader,
        CustomActivatorSelect,
        BaseInputOutlined,
    },

    data: () => ({
        pageLoading: false,
        dashboardDataLoading: false,
        dateMenu: false,
        dates: [] as string[],
        staffNames: [] as string[],
        staffNamesList: [] as string[],
        dateFormat: 'DD.MM.YYYY',
        datePickerDateFormat: 'YYYY-MM-DD',
        filters: { productsDisplay: 0 } as any,
        filtersLists: {} as any,
        inititalFilters: {},
        projectType: null as any,
        statByDateDataHeaders: [
            { text: 'День', value: 'visitDate' },
            { text: 'Количество клиентов ', value: 'clientCount', sort: numSort },
            { text: 'Клиентов перезаписано', value: 'recordedOtherDay', sort: numSort },
            { text: 'Процент перезаписи', value: 'recordedOtherDayProportion', sort: floatNumSort },
        ],

        dashboardData: {
            statByStaffData: [],
        } as any,
    }),
    watch: {},
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        projectIsNetwork(): boolean {
            return this.projectType === 'network'
        },
        connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: 'HR платформа для Idol Face',
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'ОПЗ',
                    to: '',
                    disabled: true,
                },
            ]
        },
        dateRangeText(): string {
            const formatedDates = this.sortedDates.map(date =>
                moment(date, this.datePickerDateFormat).format(this.dateFormat)
            )
            return formatedDates.join(' - ')
        },
        sortedDates(): string[] {
            return [
                ...this.dates.sort(
                    (a, b) =>
                        moment(a, this.datePickerDateFormat).unix() -
                        moment(b, this.datePickerDateFormat).unix()
                ),
            ]
        },
        dateBorders(): { to: string; from: string } {
            return {
                from: this.sortedDates[0],
                to: this.sortedDates[1],
            }
        },
        displayFilters(): any {
            return {
                filialList: Object.values(this.filters.filialList ?? {})
                    .filter((el: any) => el.selected)
                    .map((el: any) => el.id),
            }
        },
        queryFilters(): any {
            return _.pickBy(
                {
                    dateFrom: this.sortedDates[0],
                    dateTo: this.sortedDates[1],
                    filialList: this.projectIsNetwork ? this.filters.filialList : undefined,
                },
                (v, k) => {
                    if (_.isObject(v) && _.isEmpty(v)) {
                        return false
                    }
                    if (
                        !_.isNil(v) &&
                        this.displayFilters[k] &&
                        (this.filtersLists[k].length === this.displayFilters[k].length ||
                            this.displayFilters[k].length === 0)
                    ) {
                        return false
                    }
                    return true
                }
            )
        },
        statByDateData(): any[] {
            const formatNumber = (num: number) => new Intl.NumberFormat('ru-RU').format(num)
            return this.dashboardData.statByDateData.map((el: any) => ({
                visitDate: el.visit_date,
                clientCount: formatNumber(el.client_count ?? 0),
                recordedOtherDay: formatNumber(el.recorded_other_day ?? 0),
                recordedOtherDayProportion: `${formatNumber(el.recorded_other_day_proportion ?? 0)}%`,
            }))
        },
    },
    methods: {
        setFiltersLists() {
            this.filtersLists = {
                filialList: Object.values(this.filters.filialList ?? {}).map((el: any) => ({
                    id: el.id,
                    name: el.option,
                })),
            }
        },
        setDefaultDates() {
            this.dates = [
                moment()
                    .subtract(60, 'days')
                    .format(this.datePickerDateFormat),
                moment()
                    .subtract(30, 'days')
                    .format(this.datePickerDateFormat),
            ]
        },

        dropFilters() {
            this.setDefaultDates()
            this.filters = Object.assign({}, this.inititalFilters)
            this.initData()
        },
        setFilterValue(filterId: string, val: any) {
            const updatedFilterVal = {}
            const selectedHmap = val.reduce((a: any, c: any) => Object.assign(a, { [c]: true }), {})
            this.filtersLists[filterId].forEach((el: any) => {
                _.setWith(
                    updatedFilterVal,
                    [el.id],
                    {
                        id: el.id,
                        option: el.name,
                        selected: Boolean(selectedHmap[el.id]),
                        service_group: el.service_group,
                    },
                    Object
                )
            })
            this.filters = Object.assign({}, this.filters, { [filterId]: updatedFilterVal })
        },
        async initData() {
            this.dashboardDataLoading = true
            const { data, error } = await api.gcb2.getSameDayRecReportData({
                projectId: this.project.id,
                filters: this.queryFilters,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
            this.dashboardData = data.reportData
            this.filters = Object.assign({}, this.filters, data.filters)
            this.setFiltersLists()
            this.dashboardDataLoading = false
        },
    },
    async mounted() {
        this.inititalFilters = Object.assign({}, this.filters)
        this.pageLoading = true
        this.setDefaultDates()
        this.projectType = await api.project
            .getType({ projectId: this.project.id })
            .then(res => res.data?.type)
        await this.initData()
        this.pageLoading = false
    },
})
